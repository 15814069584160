var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{attrs:{"light":""}},[_c('v-toolbar-title',[_vm._v(" Manage Users ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","to":"/main/admin/users/create"}},[_vm._v("Create User")])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.users},scopedSlots:_vm._u([{key:"item.is_active",fn:function(ref){
var item = ref.item;
return [(item.is_active)?_c('v-icon',[_vm._v("checkmark")]):_vm._e()]}},{key:"item.is_superuser",fn:function(ref){
var item = ref.item;
return [(item.is_superuser)?_c('v-icon',[_vm._v("checkmark")]):_vm._e()]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{attrs:{"slot":"activator","to":{name: 'main-admin-users-edit', params: {id: item.id}}},slot:"activator"},[_c('v-icon',[_vm._v("edit")])],1)]}}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }